.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.align-items-center {
  align-items: center !important;
}

.text-center {
  text-align: center !important;
}

.p-relative {
  position: relative !important;
}

.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.chartjs-render-monitor {
  margin: auto;
}

